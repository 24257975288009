<!--  -->
<template>
  <div class="about-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '关于',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="main-wrapper">
      <van-image
        width="1.56rem"
        height="1.56rem"
        :src="require('@/assets/logo.png')"
      />
      <p class="version">当前版本v1.0</p>
      <p class="checkout-update" @click="checkUpdate">检查更新</p>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {};
  },

  components: { TopWrapper },

  computed: {},

  mounted() {},

  methods: {
    checkUpdate() {
      Toast("暂无更新版本");
    },
  },
};
</script>
<style lang="scss" scoped>
.about-page {
  width: 100%;
  height: 100%;
  background: #fafafa;
  position: relative;
  overflow: hidden;
  .main-wrapper {
    // padding: 1.9rem 2.5rem 0rem 2.5rem;
    padding-top: 1.9rem;
    text-align: center;
    .version {
      width: 100%;
      height: 0.42rem;
      margin-top: 0.42rem;
      font-size: 0.3rem;
      font-weight: 600;
      color: #666666;
      line-height: 0.42rem;
    }
    .checkout-update {
      width: 100%;
      margin-top: 0.2rem;
      font-size: 0.26rem;
      font-weight: 400;
      color: #0094ee;
      line-height: 0.37rem;
    }
  }
}
</style>
